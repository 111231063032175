@import '~antd/dist/antd.css';

body {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: rgb(48, 113, 80);
  background: linear-gradient(
    6deg,
    rgba(48, 113, 80, 1) 0%,
    #328775 70%,
    rgba(46, 120, 125, 1) 100%
  );
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
}

image {
  max-width: 100%;
}

.light {
  background: white;
}

.light a,
.light div,
.light p,
.light span,
.light .inputField,
.light .ant-form-item-explain-error {
  color: rgba(0, 0, 0, 0.9);
}

a {
  text-decoration: none;
}

.form-btn {
  background-color: rgb(48, 113, 80);
  color: white;
  border: 0;
  line-height: 18px;
}

.form-btn span {
  color: white;
}

.form-btn:hover {
  background-color: rgba(46, 120, 125, 1) !important;
}

.white-bg {
  background-color: white !important;
}

.inputField {
  width: 100%;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.08);
  color: white;
  min-height: 58px;
  padding-left: 18px;
}

.inputField.bright {
  background-color: white;
  color: black;
}

.marginBottom {
  margin-bottom: 1.7em;
}

.searchField {
  width: 100%;
  background: rgba(255, 255, 255, 0.08);
  color: white;
  min-height: 58px;
}

.searchField > span {
  border-radius: 12px;
}

.cascadeField {
  border-radius: 12px !important;
}

.dark .inputField,
.dark .searchField {
  border-color: rgba(255, 255, 255, 0.7);
}

.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 0 !important;
}

.meta-text {
  font-size: 0.8em;
}

.card-list .ant-list-item {
  background-color: white;
  border-radius: 12px;
  margin: 10px 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.hidden-btn {
  border: 0;
  padding: 0 !important;
}

.no-border {
  border: 0 !important;
}

.dark .ant-form-item-explain-error {
  color: white !important;
  font-weight: 500;
  text-align: center;
}

.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background: rgba(255, 255, 255, 0.11) !important;
}

.dark .ant-input-affix-wrapper-focused,
.dark .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: rgba(255, 255, 255, 1) !important;
}

.dark .inputField span,
.dark .inputField input,
.dark .ant-form-item-has-error span,
.dark .ant-form-item-has-error input,
.dark .ant-form-item-has-error .ant-input {
  background: transparent !important;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.inputField input {
  margin-left: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 20px;
}

.App-header {
  justify-content: center;
  display: flex;
  padding-top: 2em;
}

.App-titlebar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 1em;
  z-index: 1002;
  text-align: center;
}

.App-titlebar img {
  width: 40%;
  max-width: 380px;
  height: auto;
}

.home-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.05);
  margin-top: 65px;
  padding: 2em;
  max-height: calc(100vh - 130px);
  margin-bottom: 65px;
  overflow: scroll;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

.watermark {
  position: absolute;
  top: 50%;
  transform: translateY(-30%);
  left: 2em;
  right: 2em;
  opacity: 0.03;
}

.App-logo {
  min-height: 32px;
  height: 16vh;
}

.white-logo {
  filter: grayscale(1) brightness(100);
}

.ant-menu-item-active {
  border: 0 !important;
}

.position-right {
  position: absolute;
  right: 1em;
}

.Toastify__toast {
  margin: 1em;
  background-color: black;
}

.back-btn {
  position: relative;
  left: 0;
}

.plus-btn {
  background-color: #328775;
  align-self: flex-end;
  margin-bottom: 1em;
}

.row-btn {
  display: flex;
  flex-direction: row;
}

.plus-btn:hover,
.plus-btn:focus,
.plus-btn:active {
  background-color: #328775;
  border: 0 !important;
}

.green-icon {
  color: rgb(48, 113, 80) !important;
}

.pad-right {
  margin-right: 8px;
}

.comment {
  font-size: 0.9em;
  margin: 1em;
}

.plus-btn span {
  color: white;
  font-size: 14px;
}

.navbar-btn {
  border: 0 !important;
}

.navbar-btn span,
.ant-menu-item {
  font-weight: 700 !important;
  color: rgb(0, 0, 0, 0.3);
  font-size: 17px !important;
}

.ant-menu {
  display: flex;
  flex-direction: row;
  padding: 0.8em;
}

.ant-menu-item .anticon {
  margin-right: 0 !important;
}

.board-form {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 1.3em;
  margin-bottom: 1.5em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.edit-icon {
  border: 0 !important;
  box-shadow: none !important;
  width: 25px;
  height: 25px;
}

.edit-icon span {
  color: #328775;
}

.ant-menu-item,
.navbar-btn {
  display: flex !important;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.ant-menu-item-selected {
  border: 0 !important;
}

.ant-menu-item-selected button > span {
  color: rgb(48, 113, 80);
}
